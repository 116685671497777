import type { NextPage } from 'next';
import { signIn } from 'next-auth/react';
import { useEffect } from 'react';

const Login: NextPage = () => {
  useEffect(() => {
    function handleLogin() {
      signIn('demo-identity-server', { callbackUrl: '/' });
    }
    handleLogin();
  }, []);
  return null;
};

export default Login;
